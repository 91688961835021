import React, { useState } from "react";
import { useForm } from "react-hook-form";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { postRequest } from "../../../Globals/GlobalFunctions/Axiosfunctions";

const override = css`
  display: block;
  margin: auto;
  border-color: red;
`;

export default function ConfirmationSection(props) {
  let [ShowLoader, setShowLoader] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let params = {
      names: data.names,
      attending: data.attending === "true",
      event_id: props.eventId,
    };
    setShowLoader(true);
    console.log(props, "lll", params);
    const save = await postRequest("guests", params);

    if (save.hasError) {
      setShowLoader(false);
      alert("Sorry We Couldn't Confirm please Try Again Later!");
    } else {
      setShowLoader(false);

      alert("Thank You For Your Confirmation!");
      // else  alert('We are sorry you will not join us')
      reset({
        names: null,
        attending: "",
      });
    }

    // axios({
    // 	method: 'post',
    // 	url: `http://localhost:8000/api/v1/guests`,
    // 	// url: `https://invitationsbe.carvitec.com/api/v1/guests`,
    // 	data: JSON.stringify(params),
    // 	headers: {
    // 		Accept: 'application/json',
    // 		'Content-Type': 'application/json'
    // 	}
    // })
    // 	.then((response) => {
    // 		setShowLoader(false)

    // 			alert('Thank You For Your Confirmation!')
    // 			// else  alert('We are sorry you will not join us')
    // 			reset({
    // 				names: null,
    // 				attending:''
    // 			})
    // 	})
    // 	.catch((error) => {
    // 		setShowLoader(false)
    // 		alert("Sorry We Couldn't Confirm please Try Again Later!")
    // 		console.log(error)
    // 	});
  };

  return (
    <div className="traditionalSection textBlack">
      {ShowLoader ? (
        <div className="Loader">
          <div className="LoaderCont">
            <RingLoader
              css={override}
              size={45}
              color={"#fff"}
              loading={true}
            />
          </div>
        </div>
      ) : null}
      <div className="font18 mt20 times ">{props.slide.attribute_1}</div>

      <form onSubmit={handleSubmit(onSubmit)} className="attendanceForm">
        {/* register your input into the hook by invoking the "register" function */}
        <label className="font18 times">الأسماء</label>
        <input
          className="mt5 formInputsAr borderBlack textBlack"
          placeholder="اسماء الحاضرين"
          {...register("names", { required: true })}
        />
        {errors.names && (
          <span className="fontRed font18">This field is required</span>
        )}

        <label className="font18 mt20 times">تأكيد الحضور</label>
        <select
          className="mt5 formInputsAr borderBlack textBlack"
          {...register("attending", { required: true })}
        >
          <option className="opt" value="">
            -اختيار-
          </option>
          <option className="opt" value={true}>
            نعم
          </option>
          <option className="opt" value={false}>
            كلّا
          </option>
        </select>
        {/* errors will return when field validation fails  */}
        {errors.attending && (
          <span className="fontRed font18">This field is required</span>
        )}

        <button type="submit" className="submitBtn mt25 textWhite">
          RSVP
        </button>
      </form>

      {/* <div className='font16 LatoRegular wordSpacing mt25'>For those who wish a wedding list <br/>
			 will be available at any WHISH Money branch. <br/>
			 Account number: 20471853
			 </div> */}
    </div>
  );
}
