import React, { useEffect, useState } from "react";

// import { LinearCopy } from "gl-react";
// import { Surface } from "gl-react-dom";
// import GLTransition from "react-gl-transition";
// import GLTransitions from "gl-transitions";
// import timeLoop from "../HOC/timeLoop";
import images from "./images";

import image1 from "../Assets/Diana/1.jpg";
import image2 from "../Assets/Diana/2.jpg";
import image3 from "../Assets/Diana/3.jpg";
import image4 from "../Assets/Diana/4.jpg";

import "react-slideshow-image/dist/styles.css";
import { Zoom } from "react-slideshow-image";

import { Buffer } from "buffer";
global.Buffer = Buffer;

const imagess = [image1, image2, image3, image4];

// const Slideshow = timeLoop(({ slides, delay, duration, time, height, width }) => {
//     const index = Math.floor(time / (delay + duration));
//     const from = slides[index % slides.length];
//     const to = slides[(index + 1) % slides.length];
//     const transition = GLTransitions[38];
//     // const transition = GLTransitions[index % GLTransitions.length];
//     const total = delay + duration;
//     const progress = (time - index * total - delay) / duration;
//     return progress > 0 ? (
//       <div style={{height:height, width:width}}>
//       <p>{width} {height}</p>
//       <GLTransition
//         from={from}
//         to={to}
//         progress={progress}
//         transition={transition}
//       />
//       </div>
//     ) : (
//       <LinearCopy>{from}</LinearCopy>
//     );
//   });

export default function ImagesSlideShow({ width, height, photos }) {
  console.log({ photos });

  let images = [];
  let imgs = [];
  photos.forEach((element) => {
    const p = `${process.env.REACT_APP_BACKEND_URL}${element.link}`;
    const loadImg = new Image();
    loadImg.src = `${process.env.REACT_APP_BACKEND_URL}${element.link}`;
    console.log({ loadImg });
    images.push(loadImg);
    imgs.push(`${process.env.REACT_APP_BACKEND_URL}${element.link}`);
  });

  return (
    // <Surface width={width} height={height}>

    //   <Slideshow slides={images} delay={4500} duration={1000} height={height}/>
    // </Surface>
    <div className="slideshowContainer">
      <Zoom
        scale={1.4}
        indicators={false}
        duration={2500}
        arrows={false}
        pauseOnHover={false}
        cssClass="slideshowSubCont"
      >
        {imgs.map((img, index) => (
          <div key={index} className="slideshowSubCont">
            <img
              // style={{ objectFit: "cover", width: "100%" }}
              className="slideShowImage"
              alt="Slide Image"
              src={img}
            />
          </div>
        ))}
      </Zoom>
    </div>
  );
}
