import React, { useEffect, useState } from "react";
import ConfirmationSection from "./ConfirmationSection";
import Location from "./Location";
import { getRequest } from "../../../Globals/GlobalFunctions/Axiosfunctions";
import { useParams } from "react-router";
import Player from "../../Slides/Player";
import MainSlide from "../../Slides/MainSlide";

export default function Traditional() {
  let { slug } = useParams();

  const [initiallySmall, setInitiallySmall] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  let [started, setStarted] = useState(false);
  let [event, setEvent] = useState(false);
  let [slides, setSlides] = useState([]);
  const searchParams = new URLSearchParams(document.location.search);

  useEffect(() => {
    const getEvent = async () => {
      //   setisLoading(true)
      const event = await getRequest(`get_event_by_slug/${slug}`);
      //   setisLoading(false)
      if (!event.hasError) {
        setEvent(event.data.data);
        const currentSlides = event.data.data.slides;
        let parsedSlides = [];
        currentSlides.forEach((slide) => {
          parsedSlides.push(JSON.parse(slide.attribute_1));
        });
        setSlides(parsedSlides);
      } else {
        // console.log(data)
      }
    };

    getEvent();
    return () => {};
  }, []);

  // useEffect(() => {
  //     console.log({slug})
  //     const getEvent = async () => {
  //     //   setisLoading(true)
  //       const event = await getRequest(`get_event_by_slug/${slug}`);
  //       console.log(event.data.data)
  //     //   setisLoading(false)
  //       if (!event.hasError) {
  //         setEvent(event.data.data);
  //       }else{
  //         // console.log(data)
  //       }
  //     };

  //     getEvent();
  //     return () => {};
  //   }, []);

  useEffect(() => {
    if (window.innerWidth > 550) {
      setInitiallySmall(false);
    } else {
      setInitiallySmall(true);
    }
  }, []);

  useEffect(() => {
    if (!initiallySmall) {
      function handleResize() {
        console.log(getWindowDimensions());
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    } else {
      setWindowDimensions(getWindowDimensions());
    }
  }, [initiallySmall]);

  function getWindowDimensions() {
    //const { innerWidth: width, innerHeight: height } = window;
    let height, width;
    if (initiallySmall) {
      width = window.innerWidth;
      height = window.outerHeight;
    } else {
      height = window.outerHeight;
      width = (height * 9) / 16;
    }
    // alert(width + "|" + height)
    return {
      width,
      height,
    };
  }

  return (
    <section
      className="MainContainer parallax textBlack"
      style={{
        height: windowDimensions.height,
        width: windowDimensions.width,
        backgroundImage: `url(
          ${process.env.REACT_APP_BACKEND_URL}${event?.cover_image}
        )`,
      }}
    >
      {/* {windowDimensions.height} */}&nbsp;
      {/* <div
        className="ElelmentContainer otherCls topEl"
        style={{
          height: windowDimensions.height,
          width: windowDimensions.width,
          backgroundColor: "red",
        }}
      > */}
      {started ? (
        <section className="contentSubContainer">
          <section className="contentContainer">
            {slides.map((slide, index) => (
              <div className={slide.className} key={index}>
                {slide.sections.map((sec, index2) => {
                  if (sec.isLocation)
                    return (
                      <Location
                        place={sec.place}
                        city={sec.city}
                        location={sec.content}
                        textColor={sec.textColor}
                        borderColor={sec.borderColor}
                        key={index2}
                      />
                    );
                  else if (sec.isLink)
                    return (
                      <div className={`linkCls ${sec.className}`} key={index2}>
                        <a href={sec.link} target="_blank">
                          {sec.content}
                        </a>
                      </div>
                    );
                  else
                    return (
                      <div className={sec.className} key={index2}>
                        {sec.content}
                      </div>
                    );
                })}
              </div>
            ))}
            {/* <div className="traditionalSectionRow times contentAround fontBold">
              <div className="font16 LatoLight">
                Join us for the virtual celebration
              </div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold">
              <div className="font16 LatoLight">of</div>
            </div>
            <div className="traditionalSectionRow times contentAround">
              <div className="font60 textFuchsia GreatVibes">Dimitra's</div>
            </div>
            <div className="traditionalSectionRow times contentAround">
              <div className="font60 textFuchsia GreatVibes">
                First Birthday
              </div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold">
              <div className="font16 LatoLight">Saturday October 26th at</div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold">
              <div className="font16 LatoLight">5:00pm (Berlin Time)</div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold">
              <div className="font16 LatoLight">6:00pm (Beirut Time)</div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold pt30">
              <div className="font16 LatoLight">via Zoom using this link</div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold">
              <div className="font16 LatoLight">link</div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold pt30">
              <div className="font16 LatoLight">
                We will ask you to turn your cameras on
              </div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold">
              <div className="font16 LatoLight">Singing is mandatory</div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold">
              <div className="font16 LatoLight">
                Having a slice of cake is optional
              </div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold pt30">
              <div className="font16 LatoLight">
                We can't wait to celebrate with you
              </div>
            </div>
            <div className="traditionalSectionRow times contentAround fontBold">
              <div className="font16 LatoLight">
                Mimi's first trip around the sun
              </div>
            </div> */}
          </section>
          {event?.audio && (
            <div
              style={{
                position: "absolute",
                bottom: 75,
                left: 35,
                zIndex: 99999,
                width: "60px",
              }}
            >
              <Player audio={event.audio} />
            </div>
          )}
        </section>
      ) : (
        <MainSlide onStartClicked={() => setStarted(true)} />
      )}
      {/* </div> */}
    </section>
  );
}
