import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import SwipeLeft from './SwipeLeft';
import axios from 'axios'
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";


const override = css`
display: block;
margin:auto;
border-color:red;`


export default function Slide6() {

	let [ ShowLoader, setShowLoader ] = useState(false);

	const { register, handleSubmit, reset , watch, formState: { errors } } = useForm();
  const onSubmit = data => {

	let params={
		names: data.names,
		attending: (data.attending === 'true')
	}
    setShowLoader(true)
console.log(params)
	axios({
    	method: 'post',
    	url: `https://invitationsbe.carvitec.com/api/v1/guests`,
    	data: JSON.stringify(params),
    	headers: {
    		Accept: 'application/json',
    		'Content-Type': 'application/json'
    	}
    })
    	.then((response) => {
			setShowLoader(false)

				alert('Thank You For Your Confirmation!')
				// else  alert('We are sorry you will not join us')
				reset({
					names: null,
					attending:''
				})
    	})
    	.catch((error) => {
			setShowLoader(false)
			alert("Sorry We Couldn't Confirm please Try Again Later!")
    		console.log(error)
    	});
  };

	return (

		<div className="slide6">
		{ShowLoader ? <div className='Loader'>
			<div className='LoaderCont'>
			<RingLoader
				css={override}
				size={45}			  
				color={"#fff"}
				loading={true}
			/>
			</div>
		</div> : null}
		<SwipeLeft />
		<div className='font50 PrestigeSignatureScript'>Be our guest</div>
			
			<div className='font19 mt35 LatoRegular '>Please reply before January 10th, 2023</div>
			
			<form onSubmit={handleSubmit(onSubmit)} className='attendanceForm'>
			{/* register your input into the hook by invoking the "register" function */}
			<label className='font18 LatoRegular'>Name(s)</label>
			<input className='mt5 formInputs' placeholder="e.g: John and Jane Doe" {...register("names", { required: true })} />
			{errors.names && <span className='fontRed font18'>This field is required</span>}
			
			<label className='font18 mt20 LatoRegular'>Are you attending?</label>
			<select className='mt5 formInputs' {...register("attending", { required: true })}>
					<option className='opt' value=''>-select-</option>
					<option className='opt' value={true}>Yes</option>
					<option className='opt' value={false}>No</option>
				</select>
			{/* errors will return when field validation fails  */}
			{errors.attending && <span className='fontRed font18'>This field is required</span>}
			
			<button type="submit" className='submitBtn mt25'>RSVP</button>
			</form>

			{/* <div className='font16 LatoRegular wordSpacing mt25'>For those who wish a wedding list <br/>
			 will be available at any WHISH Money branch. <br/>
			 Account number: 20471853
			 </div> */}

		</div>

	);
}
