import React from 'react'

export default function SwipeLeft() {
  return (
    <div className='SwipeLeftContainer'>
        <div className='swipe LatoLight textWhite'> SWIPE LEFT</div>
        <div className='shevrons textWhite'>&#8249;&#8249;&#8249;</div>
    </div>
  )
}
