import React, {useEffect} from 'react';
import image from '../../Assets/Jessica/11.jpg'
import { BsSuitHeart } from "react-icons/bs";
import SwipeLeft from './SwipeLeft'

export default function Slide7 (props) {
    
	return (
		<div className='SlideContainer contentStart pt40'>
		<div class="example2">
			<div className='imageContainer'>
				<img src={props.image} className='lastImage' alt=''/>
				<div className='textBlack font16 mt15'><span className='font14 marginaround2'><BsSuitHeart/></span> <span className='font12 marginaround2 LatoRegular'>Forever</span> <span className='font14 marginaround2'><BsSuitHeart/></span></div>
			</div>
			</div>

			<div className="carvitecLink LatoLight font14 textWhite"><a className='linkTag' href='https://www.instagram.com/invites/contact/?i=21evx12fbpyy&utm_content=l4t0oan' target="_blank" rel="noreferrer">Powered By Carvitec</a></div>
		</div>
	);
}
