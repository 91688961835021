import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import MainCanvas2 from "./Components/Canvas/MainCanvas2";
import ImagesSlideShow from "./Components/ImagesSlideShow";
import DetailsSwiper from "./Components/Slides/DetailsSwiper";
import MainSlide from "./Components/Slides/MainSlide";
import { Home } from "./Components/Home";
import { SlideShow } from "./Components/Slides/SlidesShow";
import Traditional from "./Components/Templates/Traditional/Traditional";
import Birthday from "./Components/Templates/Birthday/Birthday";

function App() {
  const [initiallySmall, setInitiallySmall] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  let [started, setStarted] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 550) {
      setInitiallySmall(false);
    } else {
      setInitiallySmall(true);
    }
  }, []);

  useEffect(() => {
    if (!initiallySmall) {
      function handleResize() {
        console.log(getWindowDimensions());
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    } else {
      setWindowDimensions(getWindowDimensions());
    }
  }, [initiallySmall]);

  function getWindowDimensions() {
    //const { innerWidth: width, innerHeight: height } = window;
    let height, width;
    if (initiallySmall) {
      width = window.innerWidth;
      height = window.innerHeight;
    } else {
      height = window.innerHeight;
      width = (height * 9) / 16;
    }

    return {
      width,
      height,
    };
  }

  return (
    <Router>
      <Routes>
        <Route path="/slideshow/:slug" element={<SlideShow />} />
        <Route path="/traditional/:slug" element={<Traditional />} />
        <Route path="/birthday/:slug" element={<Birthday />} />
      </Routes>
    </Router>
  );
}

export default App;
