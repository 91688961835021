import React from "react";
import { FaRegCalendarCheck, FaMapMarkerAlt } from "react-icons/fa";

export default function Location(props) {
  return (
    <section className="times">
      <div className="font18">{props.place}</div>
      <div className="font18 mt5">{props.city}</div>
      <div className=" font36">
        <a
          className={`${props.textColor}`}
          href={props.location}
          target="_blank"
          rel="noreferrer"
        >
          <FaMapMarkerAlt />
        </a>
      </div>
      <div className="mt5 minHeight40">
        <a
          className={`locationLink LatoRegular ${props.textColor} ${props.borderColor}`}
          href={props.location}
          target="_blank"
          rel="noreferrer"
        >
          Location Map
        </a>
      </div>
    </section>
  );
}
