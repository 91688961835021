import React, { useState, useEffect } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { BsMusicNote, BsHeart } from "react-icons/bs";
  

const Player = ({audio}) => {
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
      const audioEl = document.getElementsByClassName("audio-element")[0]
      if(playing) audioEl.pause();
      else audioEl.play();
      
    
      return () => {
        
      }
    }, [playing])

    console.log({audio})
    

    function playAudio() {
      setPlaying(prev => !prev);

      }

  return (
    <div className="audioContainer">
        <button onClick={playAudio} className='audioButton'>
        <BsHeart className="audioIconHeart"/>
        <BsMusicNote className="audioIcons"/>
    </button>
    <audio className="audio-element" loop >
          <source src={`${process.env.REACT_APP_BACKEND_URL}${audio}`}></source>
        </audio>
    </div>
  );
};

export default Player;