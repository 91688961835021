import React from "react";

export const PhoneNumberWithName = ({
  className,
  subClassName1,
  subClassName2,
  number,
  numberText,
  content,
}) => {
  return (
    <div className={`${className} width75`}>
      <span className={subClassName1}>{content}</span>
      <span className="phoneWidth">
        <a className={`${subClassName2} phoneBorder`} href={`tel:${number}`}>
          {numberText}
        </a>
      </span>
    </div>
  );
};
