import axios from 'axios';

const SERVER_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_API_PATH}${process.env.REACT_APP_BACKEND_VERSION}`;

export const getRequest = async requestUrl => {
  const data = await axios
    .get(`${SERVER_URL}${requestUrl}`)
    .then(response => {
      return {
        data: response.data,
        hasError: false,
      };
    })
    .catch(error => {
      return {
        data: error,
        hasError: true,
      };
    });

  return data;
};

export const postRequest = async (requestUrl, params) => {
  const data = await axios
    .post(`${SERVER_URL}${requestUrl}`, params)
    .then(response => {
      if (response.data.status_code === 422) {
        return {
          data: response.data,
          hasError: true,
        };
      }
      return {
        data: response.data,
        hasError: false,
      };
    })
    .catch(error => {
      console.log(error)
      return {
        data: error,
        hasError: true,
      };
    });

  return data;
};

export const postRequestWithMedia = async (requestUrl, params) => {
  console.log(params);
  const data = await axios
    .post(`${SERVER_URL}${requestUrl}`, params, {
      headers: {'Content-Type': `multipart/form-data`},
    })
    .then(response => {
      console.log(response.data, 'response');
      if (response.data.status_code === 422) {
        return {
          data: response.data,
          hasError: true,
        };
      }
      return {
        data: response.data,
        hasError: false,
      };
    })
    .catch(error => {
      console.log(error.message);
      return {
        data: error,
        hasError: true,
      };
    });

  return data;
};
