import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import "../App.css";
import MainCanvas2 from "../Components/Canvas/MainCanvas2";
import ImagesSlideShow from "../Components/ImagesSlideShow";
import DetailsSwiper from "../Components/Slides/DetailsSwiper";
import MainSlide from "../Components/Slides/MainSlide";

export const Home = () => {
  const [initiallySmall, setInitiallySmall] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  let [started, setStarted] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 550) {
      setInitiallySmall(false);
    } else {
      setInitiallySmall(true);
    }
  }, []);

  useEffect(() => {
    if (!initiallySmall) {
      function handleResize() {
        console.log(getWindowDimensions());
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    } else {
      setWindowDimensions(getWindowDimensions());
    }
  }, [initiallySmall]);

  function getWindowDimensions() {
    //const { innerWidth: width, innerHeight: height } = window;
    let height, width;
    if (initiallySmall) {
      width = window.innerWidth;
      height = window.innerHeight;
    } else {
      height = window.innerHeight;
      width = (height * 9) / 16;
    }

    return {
      width,
      height,
    };
  }

  return (
    <div
      className="MainContainer"
      style={{ height: windowDimensions.height, width: windowDimensions.width }}
    >
      <div
        className="ElelmentContainer otherCls"
        style={{
          height: windowDimensions.height,
          width: windowDimensions.width,
        }}
      >
        <ImagesSlideShow
          height={windowDimensions.height}
          width={windowDimensions.width}
        />
      </div>
      <div
        className="ElelmentContainer otherCls"
        style={{
          height: windowDimensions.height,
          width: windowDimensions.width,
        }}
      >
        {started ? (
          <DetailsSwiper
            height={windowDimensions.height}
            width={windowDimensions.width}
          />
        ) : (
          <MainSlide onStartClicked={() => setStarted(true)} />
        )}
      </div>
      <div
        className="ElelmentContainer canvasCls"
        style={{
          height: windowDimensions.height,
          width: windowDimensions.width,
        }}
      >
        <MainCanvas2
          height={windowDimensions.height}
          width={windowDimensions.width}
        />
      </div>
    </div>
  );
};
