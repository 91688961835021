import React from 'react'

export default function MainSlide({onStartClicked}) {

  return (
    <div className='MainSlideContainer overlayed textWhite'>
      <div>
        <button className='tapToStartButton LatoRegular textWhite' onClick={onStartClicked}>Start</button>
      </div>
      <div className='font14 mt10 LatoLight letterSpace'>Tap To Start</div>

      <span className='PrestigeSignatureScript LatoLight LatoRegular transp'>a</span>
    </div>
  )
}



/**import Countdown from "react-countdown";

ReactDOM.render(
  <Countdown
    date={new Date(2022, 6, 18, 17, 0)}
  />, */