import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Player from './Player';
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import Slide5 from './Slide5';
import Slide6 from './Slide6';
import Slide7 from './Slide7';

import image from '../../Assets/Diana/5.jpg';
import image1 from '../../Assets/Diana/heorshe.png';


import 'swiper/css';
import GenericSlide from './GenericSlide';
import GenericSlide2 from './GenericSlide2';

export default function DetailsSwiper({ width, height, event }) {

	// console.log({event})
	return (
		<Swiper
			spaceBetween={0}
			slidesPerView={1}
			onSlideChange={(e) => console.log(e)}
			onSwiper={(swiper) => console.log(swiper)}
			className="sliderContainer overlayed"
			style={{ width: '100%', height: height, position:'relative' }}
		>
			<div style={{position:'absolute', bottom: 35, zIndex:99999, width:'60px', backgroundColor:'red'}}>
				<Player audio={event.audio}/>
				
			</div>
				{/* <div>
				<SwiperSlide className='slide'>
					<GenericSlide2 event={event}/>
				</SwiperSlide></div> */}
			{event.slides.map((slide, index) => (
				<div>{!slide.isLastSLide}
				<SwiperSlide key={index} className='slide'>
					<GenericSlide slide={slide} event={event}/>
				</SwiperSlide></div>
			))}
			{/* <SwiperSlide className="slide">
				<Slide1 />
			</SwiperSlide>
			<SwiperSlide className="slide">
				<Slide2  image={image1}/>
			</SwiperSlide>
			<SwiperSlide className="slide">
				<Slide3 />
			</SwiperSlide>
			<SwiperSlide className="slide">
				<Slide4 />
			</SwiperSlide>
			{/* <SwiperSlide className="slide">
				<Slide5 />
			</SwiperSlide> */}
			{/*<SwiperSlide className="slide">
				<Slide6 />
			</SwiperSlide>
			<SwiperSlide className="slide">{({ isActive }) => isActive && <Slide7 image={image} />}</SwiperSlide> */}
			<SwiperSlide className="slide">{({ isActive }) => isActive && <Slide7 image={`${process.env.REACT_APP_BACKEND_URL}${event.last_slide_image}`} />}</SwiperSlide>
		</Swiper>
	);
}
