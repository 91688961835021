import React, { useEffect, useState } from "react";
// import ConfirmationSection from './ConfirmationSection';
// import Location from './Location';
import Location from "../Traditional/Location";
import { getRequest } from "../../../Globals/GlobalFunctions/Axiosfunctions";
import { useParams } from "react-router";
import Player from "../../Slides/Player";
import MainSlide from "../../Slides/MainSlide";
import MainCanvas2 from "../../Canvas/MainCanvas2";
import ImagesSlideShow from "../../ImagesSlideShow";

export default function Birthday() {
  let { slug } = useParams();

  const [initiallySmall, setInitiallySmall] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  let [started, setStarted] = useState(false);
  let [event, setEvent] = useState(false);
  let [slides, setSlides] = useState([]);
  const searchParams = new URLSearchParams(document.location.search);

  useEffect(() => {
    const getEvent = async () => {
      //   setisLoading(true)
      const event = await getRequest(`get_event_by_slug/${slug}`);
      //   setisLoading(false)
      if (!event.hasError) {
        setEvent(event.data.data);
        const currentSlides = event.data.data.slides;
        let parsedSlides = [];
        currentSlides.forEach((slide) => {
          parsedSlides.push(JSON.parse(slide.attribute_1));
        });
        setSlides(parsedSlides);
      } else {
        // console.log(data)
      }
    };

    getEvent();
    return () => {};
  }, []);

  useEffect(() => {
    if (window.innerWidth > 550) {
      setInitiallySmall(false);
    } else {
      setInitiallySmall(true);
    }
  }, []);

  useEffect(() => {
    if (!initiallySmall) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    } else {
      setWindowDimensions(getWindowDimensions());
    }
  }, [initiallySmall]);

  function getWindowDimensions() {
    //const { innerWidth: width, innerHeight: height } = window;
    let height, width;
    if (initiallySmall) {
      width = window.innerWidth;
      height = window.outerHeight;
    } else {
      height = window.innerHeight;
      width = (height * 9) / 16;
    }
    // alert(width + "|" + height)
    return {
      width,
      height,
    };
  }

  return (
    <section
      className={`MainContainer textWhite ${started ? "parallax" : ""}`}
      style={{
        height: windowDimensions.height,
        width: windowDimensions.width,
        backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${event.cover_image})`,
      }}
    >
      {started && event && (
        <>
          <ImagesSlideShow
            height={windowDimensions.height}
            width={windowDimensions.width}
            photos={event.photos}
          />
          <div className="slideshowoverlay"></div>
        </>
      )}
      {/* {windowDimensions.height} */}&nbsp;
      {started ? (
        <div className="pageCont">
          {/* {event && event.floating_icons.length > 0 && (
            <div
              className="ElelmentContainer canvasCls"
              style={{
                height: windowDimensions.height,
                width: windowDimensions.width,
              }}
            >
              <MainCanvas2
                height={windowDimensions.height}
                width={windowDimensions.width}
              />
            </div>
          )} */}

          <div
            style={{
              position: "absolute",
              bottom: 35,
              zIndex: 99999,
              width: "60px",
              backgroundColor: "red",
            }}
          >
            <Player audio={`${event.audio}`} />
          </div>
          <section className="contentSubContainer">
            <section className="contentContainer ">
              {slides.map((s, index) => (
                <div className={s.className} key={index}>
                  {s.sections.map((sec, index2) => {
                    if (sec.isLocation)
                      return (
                        <Location
                          place={sec.place}
                          city={sec.city}
                          location={sec.content}
                          textColor={sec.textColor}
                          borderColor={sec.borderColor}
                        />
                      );
                    else
                      return <div className={sec.className}>{sec.content}</div>;
                  })}
                </div>
              ))}
            </section>
            <div className="carvitecLink LatoLight font14">
              <a
                className="linkTag textWhite"
                href="https://www.instagram.com/invites/contact/?i=21evx12fbpyy&utm_content=l4t0oan"
                target="_blank"
                rel="noreferrer"
              >
                Powered By Carvitec
              </a>
            </div>
          </section>
        </div>
      ) : (
        <MainSlide onStartClicked={() => setStarted(true)} />
      )}
    </section>
  );
}
