export const FIXED_STEP = 2;

// Wind
export const WIND_VELOCITY = -1; // Determines how slanted the rain drops fall, 0 = straight down

// Drop settings
export const DROP_COUNT = 8; // Adjust for more/less rain drops
export const DROP_WIDTH = 1; // Increase for thicker rain
export const DROP_X_BUFFER = 50; // How far to the sides of the screen drops will spawn
export const DROP_COLOR = "lightblue";
export const DROP_MIN_VELOCITY = 0.3;
export const DROP_MAX_VELOCITY = 0.6;
export const DROP_MIN_LENGTH = 2;
export const DROP_MAX_LENGTH = 4;
export const DROP_MIN_ALPHA = 1;
export const DROP_MAX_ALPHA = 1;

export const math = {
	// Random integer between min and max
	randomInteger: function (min, max) {
		return Math.round((Math.random() * (max - min)) + min);
	},
	randomNumber: function (min, max) {
		return (Math.random() * (max - min)) + min;
	},
	// Linear Interpolation
	lerp: function (a, b, n) {
		return a + ((b - a) * n);
	},
	scaleVector: function (v, s) {
		v.x *= s;
		v.y *= s;
	},
	normalizeVector: function (v) {
		var m = Math.sqrt(v.x * v.x + v.y * v.y);
		math.scaleVector(v, 1 / m);
	}
};