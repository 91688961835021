import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import "../../App.css";
import MainCanvas2 from "../Canvas/MainCanvas2";
import ImagesSlideShow from "../ImagesSlideShow";
import DetailsSwiper from ".//DetailsSwiper";
import MainSlide from "./MainSlide";
import { useParams } from "react-router";
import { getRequest } from "../../Globals/GlobalFunctions/Axiosfunctions";

export const SlideShow = () => {
  let { slug } = useParams();

  const [initiallySmall, setInitiallySmall] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  let [started, setStarted] = useState(false);
  let [event, setEvent] = useState(false);
  const searchParams = new URLSearchParams(document.location.search);

  useEffect(() => {
    // alert(11)
    console.log({ slug });
    const getEvent = async () => {
      //   setisLoading(true)
      const event = await getRequest(`get_event_by_slug/${slug}`);
      console.log("eventtttt", event.data.data);
      //   setisLoading(false)
      if (!event.hasError) {
        setEvent(event.data.data);
      } else {
        // console.log(data)
      }
    };

    getEvent();
    return () => {};
  }, []);

  useEffect(() => {
    if (window.innerWidth > 550) {
      setInitiallySmall(false);
    } else {
      setInitiallySmall(true);
    }
  }, []);

  useEffect(() => {
    if (!initiallySmall) {
      function handleResize() {
        console.log(getWindowDimensions());
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    } else {
      setWindowDimensions(getWindowDimensions());
    }
  }, [initiallySmall]);

  function getWindowDimensions() {
    //const { innerWidth: width, innerHeight: height } = window;
    let height, width;
    if (initiallySmall) {
      width = window.innerWidth;
      height = window.innerHeight;
    } else {
      height = window.innerHeight;
      width = (height * 9) / 16;
    }

    return {
      width,
      height,
    };
  }

  return (
    <div
      className="MainContainer"
      style={{ height: windowDimensions.height, width: windowDimensions.width }}
    >
      <div
        className="ElelmentContainer otherCls"
        style={{
          height: windowDimensions.height,
          width: windowDimensions.width,
        }}
      >
        {event && (
          <ImagesSlideShow
            height={windowDimensions.height}
            width={windowDimensions.width}
            photos={event.photos}
          />
        )}
      </div>
      <div
        className="ElelmentContainer otherCls topEl"
        style={{
          height: windowDimensions.height,
          width: windowDimensions.width,
          backgroundColor: "red",
        }}
      >
        {started ? (
          <DetailsSwiper
            height={windowDimensions.height}
            width={windowDimensions.width}
            event={event}
          />
        ) : (
          <MainSlide onStartClicked={() => setStarted(true)} />
        )}
      </div>
      {/* <div
        className="ElelmentContainer canvasCls"
        style={{
          height: windowDimensions.height,
          width: windowDimensions.width,
        }}
      >
        <MainCanvas2
          height={windowDimensions.height}
          width={windowDimensions.width}
        />
      </div> */}
    </div>
  );
};
