import React, { useState, useEffect } from 'react';
import Location from '../Templates/Traditional/Location';
import ConfirmationSection from '../Templates/Traditional/ConfirmationSection';
import { useParams } from 'react-router';
import { PhoneNumberWithName } from '../Templates/Traditional/PhoneNumberWithName';
import SwipeLeft from './SwipeLeft';

// const slide = {
//     attribute_1: "لتأكيد الحضور أو الاعتذار قبل ٦ آب ٢٠٢٣"
// }

const GenericSlide = ({slide, event, content}) => {

    const searchParams = new URLSearchParams(document.location.search)
    const slideContent = JSON.parse(slide.attribute_2)
    console.log({slideContent})

    return ( 
        <section className={slide.attribute_1}>
            <SwipeLeft />
            <div className={slideContent.className}>
                {slideContent.sub_sections.map((ss, index1) => (
                    <div key={index1} className={ss.className}>
                        {ss.sections.map((s, index2) => {
                            if(s.isLocation)
                                return (
                                    <Location place={s.place}  city={s.city}  location={s.content} />
                                )
                            else if (s.isConfirmation)
                                return(
                                    <ConfirmationSection eventId={event.id} slide={slide} content={s.content}/>
                                )
                            else if (s.isPhoneNumber)
                                return(
                                    <PhoneNumberWithName className={s.className} 
                                        subClassName1={s.subClassName1} 
                                        subClassName2={s.subClassName2}
                                        number={s.number}
                                        numberText={s.numberText}
                                        content={s.content} />
                                )
                            else if (s.isNumberOfGuests)
                                    return(
                                        (!isNaN(searchParams.get('n')) && searchParams.get('n') !== null) && <div className="font24 lh50">دعوة ل : {searchParams.get('n')}  أشخاص</div>
                                    )
                            else if (s.hasForeignContent)
                                return(
                                    <div key={index2} className={s.className}>
                                    {s.content} <span className={s.foreignContentClassName}>{s.foreignContent}</span> {s.content2}
                                </div>
                                )
                            else
                                return(<div key={index2} className={s.className}>
                                    {s.content}
                                </div>)
                            
                        }
                        )}
                    </div>
                ))}
            </div>
       </section>
     );
}
 
export default GenericSlide;